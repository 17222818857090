import(/* webpackMode: "eager" */ "/opt/build/repo/packages/frontend/frontend/frontastic/tastics/account/details/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/frontend/frontend/frontastic/tastics/account/login/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/frontend/frontend/frontastic/tastics/cart/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/frontend/frontend/frontastic/tastics/category-slider/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/frontend/frontend/frontastic/tastics/checkout/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/frontend/frontend/frontastic/tastics/content-slider/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/frontend/frontend/frontastic/tastics/content/hero/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/frontend/frontend/frontastic/tastics/content/tile/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/frontend/frontend/frontastic/tastics/content/tiles-group/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/frontend/frontend/frontastic/tastics/footer/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/frontend/frontend/frontastic/tastics/markdown/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/frontend/frontend/frontastic/tastics/not-found/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/frontend/frontend/frontastic/tastics/padi-certification-cards/cert-card-catalog/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/frontend/frontend/frontastic/tastics/padi-dive-shop/dive-shop-header/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/frontend/frontend/frontastic/tastics/padi-product/accordion/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/frontend/frontend/frontastic/tastics/padi-product/accordion/subscription/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/frontend/frontend/frontastic/tastics/padi-product/bundle-tabs/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/frontend/frontend/frontastic/tastics/padi-product/cta-action/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/frontend/frontend/frontastic/tastics/padi-product/cta-action/subscription/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/frontend/frontend/frontastic/tastics/padi-product/description/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/frontend/frontend/frontastic/tastics/padi-product/details/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/frontend/frontend/frontastic/tastics/padi-product/faq-section/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/frontend/frontend/frontastic/tastics/padi-product/feature-section/check-list/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/frontend/frontend/frontastic/tastics/padi-product/feature-section/check-list/subscription/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/frontend/frontend/frontastic/tastics/padi-product/feature-section/dynamic-list/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/frontend/frontend/frontastic/tastics/padi-product/form/sfmc-newsletter/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/frontend/frontend/frontastic/tastics/padi-product/header-section/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/frontend/frontend/frontastic/tastics/padi-product/header-section/subscription/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/frontend/frontend/frontastic/tastics/padi-product/product-list/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/frontend/frontend/frontastic/tastics/padi-product/stats/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/frontend/frontend/frontastic/tastics/padi-product/stats/subscription/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/frontend/frontend/frontastic/tastics/padi-redeem-referral/redeem-content/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/frontend/frontend/frontastic/tastics/padi-ui/accordion/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/frontend/frontend/frontastic/tastics/padi-ui/alert/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/frontend/frontend/frontastic/tastics/padi-ui/announcement-banner/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/frontend/frontend/frontastic/tastics/padi-ui/bento-box/BentoBox-33-33-33.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/frontend/frontend/frontastic/tastics/padi-ui/bento-box/BentoBox-33-66.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/frontend/frontend/frontastic/tastics/padi-ui/bento-box/BentoBox-50-50.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/frontend/frontend/frontastic/tastics/padi-ui/breadcrumbs/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/frontend/frontend/frontastic/tastics/padi-ui/category-previews/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/frontend/frontend/frontastic/tastics/padi-ui/cta-section/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/frontend/frontend/frontastic/tastics/padi-ui/cta/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/frontend/frontend/frontastic/tastics/padi-ui/currency-selector/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/frontend/frontend/frontastic/tastics/padi-ui/faq-section/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/frontend/frontend/frontastic/tastics/padi-ui/feature-section/check-list/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/frontend/frontend/frontastic/tastics/padi-ui/feature-section/dynamic-list/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/frontend/frontend/frontastic/tastics/padi-ui/footer/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/frontend/frontend/frontastic/tastics/padi-ui/form/sfmc-newsletter/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/frontend/frontend/frontastic/tastics/padi-ui/header-section/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/frontend/frontend/frontastic/tastics/padi-ui/header/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/frontend/frontend/frontastic/tastics/padi-ui/header/topbar/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/frontend/frontend/frontastic/tastics/padi-ui/hero/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/frontend/frontend/frontastic/tastics/padi-ui/incentives/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/frontend/frontend/frontastic/tastics/padi-ui/list/description/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/frontend/frontend/frontastic/tastics/padi-ui/menu/flyout-simple/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/frontend/frontend/frontastic/tastics/padi-ui/menu/menu-card/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/frontend/frontend/frontastic/tastics/padi-ui/product-features/product-features-split-image/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/frontend/frontend/frontastic/tastics/padi-ui/promotional-tile/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/frontend/frontend/frontastic/tastics/padi-ui/referral-redeem/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/frontend/frontend/frontastic/tastics/padi-ui/simplified-footer/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/frontend/frontend/frontastic/tastics/padi-ui/simplified-header/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/frontend/frontend/frontastic/tastics/padi-ui/sso-callback/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/frontend/frontend/frontastic/tastics/padi-ui/stats/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/frontend/frontend/frontastic/tastics/padi-ui/testimonials/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/frontend/frontend/frontastic/tastics/padi-ui/video-player/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/frontend/frontend/frontastic/tastics/products/details/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/frontend/frontend/frontastic/tastics/products/other-products/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/frontend/frontend/frontastic/tastics/products/product-list/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/frontend/frontend/frontastic/tastics/products/similar-products/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/frontend/frontend/frontastic/tastics/products/slider/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/frontend/frontend/frontastic/tastics/spacer/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/frontend/frontend/frontastic/tastics/thank-you/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/frontend/frontend/helpers/padi/slo/subscription/onDelete.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/frontend/frontend/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/frontend/frontend/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-no-ssr.js");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/frontend/frontend/providers/index.tsx")