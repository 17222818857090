import Button from 'components/commercetools-ui/atoms/button';
import Markdown from 'components/commercetools-ui/organisms/markdown';
import { isStringHtml } from 'helpers/utils/isStringHtml';
import { useRouter } from 'next/navigation';
import React, { FC, useMemo } from 'react';
import { UIProduct } from '../../../commercetools-ui/organisms/product/product-details/types';

export interface MoreInfoProps {
  product: UIProduct;
}

interface MoreInfoType {
  title: string;
  body: string;
  'cta-label': string;
  'cta-link': string;
}

const getMoreInfoData = (data: string) => {
  try {
    const { title, body, 'cta-label': ctaLabel, 'cta-link': ctaLink }: MoreInfoType = data ? JSON.parse(data) : {};
    return { title, body, ctaLabel, ctaLink };
  } catch (error) {
    console.error('Error parsing moreInfo:', error);
    return {};
  }
};

const MoreInfo: FC<MoreInfoProps> = ({ product }) => {
  const moreInfoData = product?.variants[0].attributes?.['section-13'];
  const router = useRouter();

  const { title, body, ctaLabel, ctaLink } = useMemo(() => getMoreInfoData(moreInfoData), [moreInfoData]);

  const truncateText = (text: string | undefined, maxLength: number) => {
    if (text && text.length > maxLength) {
      return text.slice(0, maxLength) + '...';
    }
    return text;
  };

  if (!title || !body || !ctaLabel) {
    return <></>;
  }

  const openLink = () => {
    router.push(ctaLink);
  };

  return (
    <div className="my-20 rounded-md border border-gray-200 bg-padi-gray-lighter px-16 pb-16 pt-20	">
      <p className="font-semibold">{truncateText(title, 70)}</p>
      {body && isStringHtml(body) ? (
        <div className="mt-16 text-14" dangerouslySetInnerHTML={{ __html: body }}></div>
      ) : (
        body && <Markdown className="mt-16 text-14" markdown={truncateText(body, 185) || ''} />
      )}
      <Button
        className="mt-16 w-full rounded-md border !border-[#D8D8DA] text-14 font-medium text-padi-blue"
        variant="secondary"
        onClick={openLink}
      >
        {ctaLabel}
      </Button>
    </div>
  );
};

export default React.memo(MoreInfo);
